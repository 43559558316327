// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
export type UpdateBasicInfoFragment = { __typename: 'Update', id: string, group: string, message?: string | null, createdAt: any, updatedAt: any, activityTimestamp: any, isRollBackToEmbedded: boolean, branchId: string, manifestFragment: string, updateRuntimeVersion: string, updatePlatform: string, updateGitCommitHash?: string | null, updateIsGitWorkingTreeDirty: boolean, codeSigningInfo?: { __typename?: 'CodeSigningInfo', keyid: string } | null };

export const UpdateBasicInfoFragmentDoc = gql`
    fragment UpdateBasicInfo on Update {
  __typename
  id
  group
  message
  createdAt
  updatedAt
  activityTimestamp
  isRollBackToEmbedded
  codeSigningInfo {
    keyid
  }
  branchId
  updateRuntimeVersion: runtimeVersion
  updatePlatform: platform
  updateGitCommitHash: gitCommitHash
  updateIsGitWorkingTreeDirty: isGitWorkingTreeDirty
  manifestFragment
}
    `;