import { mergeClasses } from '@expo/styleguide';
import invariant from 'invariant';
import { HTMLAttributes } from 'react';

export type TableGridColumn =
  | 'min-content'
  | 'max-content'
  | 'auto'
  | { min: string; max: string }
  | `${number}%`
  | `${number}px`
  | `${number}fr`;

export type TableRootProps = HTMLAttributes<HTMLDivElement> & {
  columnCount: number;
  theme?: 'default' | 'unstyled';
  rootClassName?: string;
  /**
   * If you need to override the default grid template columns, you can pass a string here.
   */
  gridTemplateColumns?: TableGridColumn[];
  mobileCompactMode?: boolean;
};

export function TableRoot({
  children,
  columnCount,
  theme = 'default',
  className,
  rootClassName,
  gridTemplateColumns,
  mobileCompactMode,
  ...rest
}: TableRootProps) {
  invariant(
    !gridTemplateColumns || columnCount === gridTemplateColumns?.length,
    'columnCount must have the same length as gridTemplateColumns.'
  );

  return (
    <div
      className={mergeClasses(
        'flex flex-row',
        theme === 'default' && 'rounded-lg border border-default bg-default shadow-xs',
        rootClassName
      )}>
      <div
        {...rest}
        className={mergeClasses(
          // This w-0 is important to make the table scrollable.
          'w-0 flex-1 flex-col overflow-x-auto',
          mobileCompactMode ? 'flex md:grid md:items-center' : 'grid items-center',
          !gridTemplateColumns && columnCountToClassName(columnCount),
          className
        )}
        style={
          gridTemplateColumns
            ? { gridTemplateColumns: printGridTemplateColumns(gridTemplateColumns) }
            : undefined
        }>
        {children}
      </div>
    </div>
  );
}

function printGridTemplateColumns(gridTemplateColumns: TableGridColumn[]) {
  return gridTemplateColumns
    .map((column) => {
      if (typeof column === 'object') {
        return `minmax(${column.min}, ${column.max})`;
      }

      return column;
    })
    .join(' ');
}

function columnCountToClassName(columnCount: number) {
  switch (columnCount) {
    case 1:
      return 'grid-cols-auto-min-1';
    case 2:
      return 'grid-cols-auto-min-2';
    case 3:
      return 'grid-cols-auto-min-3';
    case 4:
      return 'grid-cols-auto-min-4';
    case 5:
      return 'grid-cols-auto-min-5';
    case 6:
      return 'grid-cols-auto-min-6';
    case 7:
      return 'grid-cols-auto-min-7';
    case 8:
      return 'grid-cols-auto-min-8';
    default:
      console.warn('Only 8 columns are supported.');
      return 'grid-cols-auto-min-1';
  }
}
