import { mergeClasses } from '@expo/styleguide';
import { HTMLAttributes } from 'react';

export type CellProps = HTMLAttributes<HTMLDivElement> & {
  theme?: 'default' | 'interactive';
  hideOnMobile?: boolean;
  mobilePrimaryCell?: boolean;
};

export function TableCell({
  children,
  theme = 'default',
  className,
  mobilePrimaryCell,
  hideOnMobile,
  ...rest
}: CellProps) {
  return (
    <div
      {...rest}
      className={mergeClasses(
        hideOnMobile ? 'hidden py-4 md:flex md:py-2' : 'flex',
        theme === 'default' && 'px-4 py-2',
        theme === 'interactive' && 'm-1 rounded-md px-3 py-1 hocus:bg-element md:truncate md:py-1',
        mobilePrimaryCell &&
          theme === 'interactive' &&
          'm-0 rounded-none px-3 py-3 md:m-1 md:rounded-md md:py-1',
        className
      )}>
      {children}
    </div>
  );
}
