import { mergeClasses } from '@expo/styleguide';
import { HTMLAttributes, useContext } from 'react';

import { HeaderContext } from './TableHeader';

type HeaderCellProps = HTMLAttributes<HTMLDivElement> & {
  tooltip?: string;
  hideOnMobile?: boolean;
};

export function TableHeaderCell({
  children,
  tooltip,
  hideOnMobile,
  className,
  ...rest
}: HeaderCellProps) {
  const theme = useContext(HeaderContext).theme ?? 'primary';
  return (
    <div
      {...rest}
      className={mergeClasses(
        'flex min-h-[40px] items-center gap-1 border-secondary px-4 text-3xs',
        theme === 'secondary' && 'bg-subtle',
        hideOnMobile && 'hidden md:flex',
        className
      )}>
      {children}
    </div>
  );
}
