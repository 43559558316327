import { format } from 'date-fns/format';
import { formatDistanceToNow } from 'date-fns/formatDistanceToNow';

import { dateFnsFormats } from '~/common/constants';

const MILISECONDS_IN_MONTH = 1000 * 60 * 60 * 24 * 30;

export function getVariableTimeString(value: string) {
  const date = new Date(value);

  if (Date.now() - date.getTime() < MILISECONDS_IN_MONTH) {
    return formatDistanceToNow(date, { addSuffix: true });
  }

  return format(date, dateFnsFormats.date);
}
